import { EntityListState } from "../../types/state/entities/EntityListState";
import { Portfolio} from "../../types/portfolio/Portfolio";
import { RequestState } from "../../constants/request-state";
import { AnyAction } from "redux";
import { getType } from "typesafe-actions";
import { portfolioListActions } from "../../actions/portfolio-list.actions";
import { bwicStatuses } from "../../constants";
import { apiUtils, isRequestSuccess } from "../../utils";
import { SecurityBwicStatistics } from "../../types/security/SecurityBwicStatistics";

export const portfolioListInitialState: EntityListState<Portfolio> = {
    items: [],
    requestState: RequestState.none,
}

export function portfolioListReducer(state = portfolioListInitialState, action: AnyAction) {
    switch (action.type) {
        case getType(portfolioListActions.request):
            return {
                items: [],
                requestState: RequestState.request,
            };
        case getType(portfolioListActions.requestResult):
            return {
                items: action.payload.result,
                requestState: action.payload.requestResult,
            };
        case getType(portfolioListActions.resetEntities):
            return { ...portfolioListInitialState };
        case getType(portfolioListActions.colorDistributionSend):
            return updateSecurityHistory(
                state,
                action.payload.data,
                history => ({
                    ...history,
                    color: action.payload.data.colors.find((c: { positionId: number }) => c.positionId === history.positionId)?.color
                }));
        case getType(portfolioListActions.bwicStatusChanged):
            return updateSecurityHistory(
                state,
                action.payload.data,
                history => ({ ...history, status: action.payload.data.bwicStatus })
            );
        case getType(portfolioListActions.updateSendAlertState): {
            if (!isRequestSuccess(state.requestState)) return state;

            type TSendAlertState = { portfolioId: number, bwicAlert?: boolean, dealersInventoryAlert?: boolean };
            const sendAlerState: TSendAlertState[] = action.payload.sendAlertState;
            const sendAlerStateNormalized = apiUtils.normalize(
                sendAlerState,
                (item: TSendAlertState) => item.portfolioId,
                (item: TSendAlertState) => item
            );

            return {
                ...state,
                items: state.items.map(p => sendAlerStateNormalized[p.id]
                    ? {
                        ...p,
                        bwicAlert: sendAlerStateNormalized[p.id].bwicAlert ?? p.bwicAlert,
                        dealersInventoryAlert: sendAlerStateNormalized[p.id].dealersInventoryAlert ?? p.dealersInventoryAlert
                    }
                    : p)
            };
        }
        case getType(portfolioListActions.deletePortfolio):
            return {
                ...state,
                items: state.items.filter(item => item.id !== action.payload.portfolioId)
            }
        case getType(portfolioListActions.reset):
            return { ...portfolioListInitialState }
        default: return state;
    }
}


function updateSecurityHistory(
    state: EntityListState<Portfolio>,
    action: AnyAction,
    updateCallback: (history: SecurityBwicStatistics
    ) => SecurityBwicStatistics) {
    if (!state.items || !state.items.length || !isRequestSuccess(state.requestState)) return state;

    const { bwicReferenceName } = action;

    return {
        ...state,
        items: state.items.map(p => ({
            ...p,
            securities: p.securities && p.securities.map(s => ({
                ...s,
                history: s.history
                    .map(h => h.referenceName === bwicReferenceName ? updateCallback(h) : h)
                    .filter(h => h.status !== bwicStatuses.cancelled.key)
            }))
        }))
    };
}
